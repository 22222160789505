import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Anchor from "@components/Anchor"
import Container from "@components/container"
import StaticHero from "@components/staticHero"
import { sectionMd } from "@styles/ui"

const SiteMapShared = ({ location }) => {
  const narcolepsyLinks = [
    {
      text: "Living With Narcolepsy",
      subLinks: [
        {
          url: "/narcolepsy/about-narcolepsy/",
          text: "About Narcolepsy",
        },
        {
          url: "/narcolepsy/living-with-narcolepsy/",
          text: "Living With Narcolepsy",
        },
      ],
    },
    {
      text: "Why XYWAV",
      subLinks: [
        {
          url: "/narcolepsy/what-is-xywav/",
          text: "What is XYWAV",
        },
        {
          url: "/narcolepsy/lower-sodium-oxybate-treatment-option/",
          text: "XYWAV and Sodium",
        },
        {
          url: "/narcolepsy/transitioning-from-xyrem/",
          text: "Transitioning From XYREM",
        },
        {
          url: "/narcolepsy/faq/",
          text: "FAQ",
        },
      ],
    },
    {
      text: "Getting XYWAV",
      subLinks: [
        {
          url: "/narcolepsy/talk-to-your-doctor/",
          text: "Talking to Your Doctor",
        },
        {
          url: "/narcolepsy/getting-xywav/",
          text: "Getting XYWAV",
        },
        {
          url: "/narcolepsy/dosing/",
          text: "Dosing",
        },
        {
          url: "/narcolepsy/xywav-side-effects/",
          text: "Side Effects",
        },
      ],
    },
    {
      text: "Support & Resources",
      subLinks: [
        {
          url: "/narcolepsy/jazzcares-for-xywav/",
          text: (
            <>
              JazzCares<sup>&reg;</sup> Savings & Financial Support
            </>
          ),
        },
        {
          url: "/narcolepsy/patient-support-programs/",
          text: (
            <>
              JazzCares<sup>&reg;</sup> Patient Support Programs
            </>
          ),
        },
        {
          url: "/narcolepsy/patient-resources/",
          text: "Additional Patient Resources",
        },
        {
          url: "/narcolepsy/educational-webinars/",
          text: "Educational Webinars",
        },
      ],
    },
    {
      url: "/patient-stories/",
      text: "Patient Stories",
    },
    {
      url: "/narcolepsy/stay-connected/",
      text: "Stay Connected",
    },
  ]
  const ihLinks = [
    {
      url: "/idiopathic-hypersomnia/what-is-ih/",
      text: "What is IH?",
    },
    {
      text: "Why XYWAV",
      subLinks: [
        {
          url: "/idiopathic-hypersomnia/what-is-xywav/",
          text: "What is XYWAV?",
        },
        {
          url: "/idiopathic-hypersomnia/xywav-efficacy/",
          text: "Study Results With XYWAV",
        },
        {
          url: "/idiopathic-hypersomnia/side-effects/",
          text: "Possible Side Effects",
        },
        {
          url: "/idiopathic-hypersomnia/faq/",
          text: "Frequently Asked Questions",
        },
      ],
    },
    {
      text: "Getting XYWAV",
      subLinks: [
        {
          url: "/idiopathic-hypersomnia/talking-to-your-doctor/",
          text: "Talking to Your Doctor",
        },
        {
          url: "/idiopathic-hypersomnia/dosing/",
          text: "Taking XYWAV",
        },
        {
          url: "/idiopathic-hypersomnia/getting-xywav/",
          text: "Getting XYWAV",
        },
      ],
    },
    {
      text: "Support & Resources",
      subLinks: [
        {
          url: "/idiopathic-hypersomnia/jazzcares-for-xywav/",
          text: (
            <>
              JazzCares<sup>&reg;</sup> Savings & Financial Support
            </>
          ),
        },
        {
          url: "/idiopathic-hypersomnia/patient-support-programs/",
          text: (
            <>
              JazzCares<sup>&reg;</sup> Patient Support Programs
            </>
          ),
        },
        {
          url: "/idiopathic-hypersomnia/resources/",
          text: "Resources & Videos",
        },
      ],
    },
    {
      url: "/idiopathic-hypersomnia/stay-connected/",
      text: "Stay Connected",
    },
  ]

  return (
    <Layout
      location={location}
      headerType="home"
      footerType="landing"
      copyright={
        <>
          &copy; 2023 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <nobr>US-XYW-2200416 Rev1123</nobr>
        </>
      }
    >
      <Seo
        title="User Sitemap | XYWAV"
        description="Explore the Xywav.com sitemap. See XYWAV™ (calcium, magnesium, potassium, and sodium oxybates) oral solution Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero gradient="agnostic" alt="">
        <span tw="block pt-6 xl:pt-10">Site map</span>
      </StaticHero>

      <section css={[sectionMd, tw`text-xl lg:text-[21px]`]}>
        <Container>
          <div tw="pt-5 xl:pt-10">
            <Anchor link="/" colour="merlin">
              Dual-Indication Homepage
            </Anchor>
            <p tw="font-black mt-9 mb-7 text-xl lg:text-[21px]">
              <Anchor link="/narcolepsy/" colour="merlin">
                Narcolepsy
              </Anchor>
            </p>
            <ul tw="pl-7">
              {narcolepsyLinks.map((link, i) => {
                return link.subLinks ? (
                  <li key={i} tw="font-black my-3 xl:(my-0 mt-5)">
                    {link.text}
                    <ul tw="font-normal">
                      {link.subLinks.map((sublink, i) => {
                        return (
                          <li key={i} tw="py-2 ml-6">
                            <Anchor colour="merlin" link={sublink.url}>
                              {sublink.text}
                            </Anchor>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                ) : (
                  <li key={i} tw="font-black mb-4 xl:(mb-5 mt-4)">
                    <Anchor colour="merlin" link={link.url}>
                      {link.text}
                    </Anchor>
                  </li>
                )
              })}
            </ul>

            <p tw="font-black mt-16 mb-7 text-xl lg:text-[21px]">
              <Anchor colour="merlin" link="/idiopathic-hypersomnia/">
                Idiopathic Hypersomnia (IH)
              </Anchor>
            </p>

            <ul tw="pl-7">
              {ihLinks.map((link, i) => {
                return link.subLinks ? (
                  <li key={i} tw="font-black my-3 xl:(mb-0 mt-5)">
                    {link.text}
                    <ul tw="font-normal">
                      {link.subLinks.map((sublink, i) => {
                        return (
                          <li key={i} tw="py-2 ml-6">
                            <Anchor colour="merlin" link={sublink.url}>
                              {sublink.text}
                            </Anchor>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                ) : (
                  <li key={i} tw="font-black mb-4 xl:(mb-5 mt-4)">
                    <Anchor colour="merlin" link={link.url}>
                      {link.text}
                    </Anchor>
                  </li>
                )
              })}
            </ul>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default SiteMapShared
